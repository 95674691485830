@import "/src/css/index.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

body , .Toastify div{
  font-family: "Cairo", sans-serif;
}

.with-splash-bg {
  background: linear-gradient(140deg, rgb(0, 110, 126), rgb(0, 110, 126) 50%, rgb(249, 196, 22) 50%, rgb(249, 196, 22) 100%);
}

.new-home-bg {
 background: url('./assets/svg/background-home.svg');
  background-size: cover;
}
.ant-switch {
  background: rgba(0, 0, 0, 0.45);
}


/* body {
  background: url('./assets/svg/background-home.svg');
  background-repeat: repeat;
  background-position: center top;
  margin: 0;
  background-repeat: repeat-y;
  padding: 0;
  background-size: cover;
  /* background-height: 100px; */
/* }  */
