.stepper-progress-container {
    margin: 24px 0 15px;
    padding: 0 15px;
}

.stepper-title {
    margin-bottom: 40px;
    font-size: 14px;
}

.stepper-progress-wrapper {
    display: flex;
    width: 100%;
}

.progress-step-item {
    flex: 1;
    position: relative;

}

.progress-step-item:last-child, .progress-step-item-responsive:last-child .progress-step-responsive  {
    flex: 0;
    background: transparent;
}

.progress-step-item-responsive:last-child .progress-step-responsive {
    flex: inherit;
}

.progress-step-item-responsive:last-child {
    height: 60px;
    min-height: 20px;
    margin-bottom: 12px;
}




.progress-step-item:last-child .progress-step {
    background: transparent;
}



.progress-step-item p.step-title {
    margin-top: 40px;
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 4px;
}

 p.date {
    font-size: 15px;
     color: #60789A;
}

.stepper-progress-wrapper div.progress-step {
    background: #EEEEEE;
    flex: 1;
    height: 20px;
    position: relative;
}






.step-tooltip {
    position: absolute;
    top: -22%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 12px;
    padding: 2px 8px;
    border-radius: 4px;
    text-transform: capitalize;
}

.step-tooltip.Submitted {
    background: #D0EDDB;
    color: #16A34A;
}

.step-tooltip.PENDING {
    background: #FDECCE;
    color: #F59E0B;
}

.step-tooltip.rejected {
    background: #c40a0a  !important;
    color: #ffffff !important;
}

.stepper-progress-wrapper div.progress-step.active, .progress-step-responsive.active {
    background: #36b289;
}

.last-active {
    background: #EEEEEE !important;
}

.stepper-progress-wrapper div.progress-step::before, .progress-step-responsive::before{
    width: 50px;
    height: 50px;
    background: #ffffff;
    content: '\2713';
    position: absolute;
    transform: translate(0, -50%);
    top: 50%;
    left: 0;
    border-radius: 50%;
    outline: 5px solid #EEEEEE;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    z-index: 2;

}





.stepper-progress-wrapper div.progress-step.active::before, .progress-step-responsive.active::before {
    outline: 5px solid #36b289;
    color: #36b289;
}


.stepper-progress-wrapper div.progress-step.active.rejected::before, .progress-step-responsive.active.rejected::before {
    outline: 5px solid #c40a0a;
    color: #c40a0a;
    content: "X";
}



.stepper-progress-container-responsive {
    display: none;
    padding: 0 22px;
}


.progress-step-item-responsive {
    min-height: 110px;
    position: relative;
    display: flex;
    gap: 42px;
}

.progress-step-responsive {
    min-height: 110px;
    width: 15px;
    background: #EEEEEE;
    position: relative;
}

.progress-step-responsive::before {
    top: 23px;
    left: -17px;
}

.step-title-responsive {
    font-weight: 600;
    font-size: 15px;
}

@media screen and (max-width: 768px) {
    .stepper-progress-container-responsive {
        display: block;
    }
    .stepper-progress-container {
        display: none;
    }
}


html[dir=rtl] .stepper-progress-wrapper div.progress-step::before {
    left: inherit;
    right: 0;
    transform: translate(0, -50%) rotateY(180deg);
}