.tamweel-base-input {
    height: 56px;
    border-radius: 12px;
    font-size: 16px;
}


.tamweel-base-checkbox .ant-checkbox-inner {
    width: 24px !important;
    height: 24px !important;
}

.tamweel-base-checkbox .ant-checkbox-inner::after {
    left: 50%;
    transform: translate(-50%, -50%) rotate(42deg) !important;
}

.tamweel-base-checkbox  .ant-checkbox + span {
    font-size: 12px !important;
}

