.request-card-wrapper {
    border: 1px solid rgba(221, 223, 223, 1);
    border-radius: 20px;
    padding: 24px;
    margin-bottom: 16px;
}

/* ---------------------------------
 // start card header
 -----------------------------------*/
.request-card-wrapper .request-card-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 48px;
}

.request-card-wrapper .request-card-header .title {
    color: rgba(97, 97, 97, 1);
    font-size: 13px;
    margin-bottom: 10px;
}

.request-card-wrapper .request-card-header .text {
    color: rgba(97, 97, 97, 1);
    font-size: 20px;
    font-weight: 500;
}

.request-card-header-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
}

.button-wrapper {
    background: rgba(0, 110, 126, 1);
    min-height: 40px;
    color: #ffffff;
    border-radius: 8px;
    padding: 0 15px;
    font-size: 13px;
}



.no-result {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    margin-top: 16px;
}

.no-result p {
    margin-top: 24px;
    font-weight: 600;
    font-size: 30px;
}



.qaurar-comments {
    font-size: 14px;
    font-weight: 500;
}

.qaurar-comments span {
    color: #616161;
    display: inline;
    margin: 0 10px;
}


.request-card-header-buttons-responsive {
    display: none;
}

.responsive-app-status {
    background: #D0EDDB;
    padding: 3px 12px;
    font-size: 13px;
    color: #16A34A;
    width: max-content;
    border-radius: 4px;
    margin-top: 10px;
    display: none;
}

.responsive-app-status.rejected {
    background: #c40a0a;
    color: #ffffff;
}

@media screen and (max-width: 768px) {
    .responsive-app-status {
        display: block;
    }

    .request-card-header {
        margin-bottom: 25px !important;
    }

    .request-card-header-buttons {
        display: none;
    }

    .qaurar-comments span {
        display: block;
        margin: 0;
        margin-bottom: 10px;
    }

    .request-card-header-buttons-responsive {
        gap: 10px;
        display: flex;
        margin-top: 16px;
    }
}