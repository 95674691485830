.privacyModal .headText {
  margin-bottom: 20px;
  font-weight: 600;
  text-decoration: underline;
  font-size: 18px;
}

.privacyModal section {
  margin-bottom: 20px;
  line-height: 2em;
}

.privacyModal p {
  margin-bottom: 13px;
}

.privacyModal .subHead {
  font-weight: 600;
  margin-bottom: 16px;
}

.privacyModal ul {
  padding: 0 30px;
  margin-top: 12px;
}

.privacyModal ul li {
  list-style: disc !important;
  margin-bottom: 10px;
}