.auth_form_wrapper {
    padding: 84px 108px;
    background: rgba(250, 250, 250, 1);
    border-radius: 24px;
    max-width: 1200px;
}

.auth_form_wrapper.login-form {
    width: 584px;
    margin: auto;
}


.auth_form_wrapper .form-header-text {
    margin-bottom: 24px;
}

.auth_form_wrapper .form-header-title-wrapper {
    margin: 24px 0;
}

.auth_form_wrapper .form-header-title-wrapper .form-header-title {
    margin-bottom: 8px;
}

.auth_form_wrapper .form-header-title-wrapper .form-header-caption {
    font-size: 15px;
}

.form-button {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: end;
    flex-direction: column;
}

.form-button button {
    width: 373px;
}