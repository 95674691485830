
/* Styling the container */
.hijri-date-selector {
    width: 100%;
    display: flex;
    gap: 10px;
}

/* Styling each select input */
.hijri-date-selector select {
    padding: 7px 11px !important;
    border: 1px solid #d9d9d9;
    border-radius: 12px;
    height: 56px;
    background-color: #fff;
    color: rgb(90, 98, 113);
    font-size: 16px;
    line-height: 1.5;
    transition: all 0.3s;
    cursor: pointer;
    flex: 1;
}

/* Adding hover effect */
.hijri-date-selector select:hover {
    border-color: #40a9ff;
}

/* Adding focus effect */
.hijri-date-selector select:focus {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    outline: none;
}

/* Styling the options */
.hijri-date-selector option {
    padding: 8px 12px;
    background-color: #fff;
    color: #000;
    font-size: 14px;
}

/* Custom arrow */
.hijri-date-selector select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 12px 12px;
    padding-right: 36px;
    border: 1px solid #ccc;
    border-radius: 12px;
    background-image: url('https://www.svgrepo.com/show/80156/down-arrow.svg');
}



html[dir='rtl'] .hijri-date-selector select {
    background-position: left 12px center;
    background-image: url('https://www.svgrepo.com/show/80156/down-arrow.svg');
}